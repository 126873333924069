import { Controller } from '@hotwired/stimulus'
import { connect, createLocalVideoTrack, createLocalTracks } from 'twilio-video'
import { isMobile } from '~js/helpers/mobile'

export default class extends Controller {
  static targets = [
    'participants',
    'participantTemplate',
    'video',
  ]

  static values = {
    name: String,
    token: String,
    facingMode: { type: String, default: 'user' },
  }

  async connect() {
    const hasBackCamera = await this.checkBackCameraExistance()
    if (hasBackCamera) this.element.classList.add('-has-back-camera')

    await this.createTracks()
    this.attachVideoTrack()

    this.room = await connect(this.tokenValue, {
      name: this.nameValue,
      tracks: this.tracks,
    })

    if (isMobile()) {
      document.addEventListener('visibilitychange', async () => {
        if (document.visibilityState === 'hidden') {
          this.removeAllTracks()
        } else {
          await this.createTracks()
          await this.room.localParticipant.publishTracks(this.tracks)
          this.attachVideoTrack()
        }
      })
    }

    this.syncClassesForLocalParticipant()

    this.element.classList.remove('-loading')
    this.element.classList.add('-connected')

    this.room.participants.forEach(participant => this.renderParticipant(participant))
    this.room.on('participantConnected', participant => this.renderParticipant(participant))
    this.room.on('participantDisconnected', participant => this.removeParticipant(participant))

    window.addEventListener('pagehide', this.leave.bind(this))
    window.addEventListener('beforeunload', this.leave.bind(this))
  }

  async checkBackCameraExistance() {
    const devices = await navigator.mediaDevices.enumerateDevices()
    const videoInputDevices = devices.filter(device => device.kind === 'videoinput')

    const backCameraDevices = await Promise.all(
      videoInputDevices.map(async device => {
        const capabilities = await device.getCapabilities()
        return capabilities.facingMode?.includes('environment')
      })
    )

    return backCameraDevices.includes(true)
  }

  async changeVideoDevice() {
    this.facingModeValue = this.facingModeValue === 'user' ? 'environment' : 'user'
    this.cameraTrack.restart({ facingMode: this.facingModeValue })
  }

  async createTracks() {
    this.tracks = await createLocalTracks({
      audio: true,
      video: { facingMode: this.facingModeValue }
    })
  }

  removeAllTracks() {
    this.tracks.forEach(track => track.stop())
    this.room.localParticipant.unpublishTracks(this.tracks)
    this.dettachVideoTrack()
  }

  attachVideoTrack() {
    this.videoTarget.appendChild(this.cameraTrack.attach())
  }

  dettachVideoTrack() {
    this.videoTarget.innerHTML = ''
  }

  leave() {
    this.room.disconnect()
    this.element.classList.remove('-connected')
    this.element.classList.add('-disconnected')
  }

  disconnect() {
    window.removeEventListener('pagehide', this.leave.bind(this))
    window.addEventListener('beforeunload', this.leave.bind(this))
  }

  renderParticipant(participant) {
    const cloneNode = this.participantTemplateTarget.content.cloneNode(true)
    cloneNode.firstChild.dataset.twilioRemoteSidValue = participant.sid
    this.participantsTarget.appendChild(cloneNode)
  }

  removeParticipant(participant) {
    this.participantsTarget.querySelector(`[data-twilio-remote-sid-value="${participant.sid}"]`).remove()
  }

  audioToggle() {
    !this.hasAudio ? this.localAudioTrack.enable() : this.localAudioTrack.disable()
    this.syncClassesForLocalParticipant()
  }

  videoToggle() {
    !this.hasVideo ? this.localVideoTrack.enable() : this.localVideoTrack.disable()
    this.syncClassesForLocalParticipant()
  }

  syncClassesForLocalParticipant() {
    this.hasAudio ? this.element.classList.add('-audio') : this.element.classList.remove('-audio')
    this.hasVideo ? this.element.classList.add('-video') : this.element.classList.remove('-video')
  }

  get cameraTrack() {
    return this.tracks.find(track => track.kind === 'video')
  }

  get hasAudio() {
    return this.localAudioTrack.isEnabled
  }

  get hasVideo() {
    return this.localVideoTrack.isEnabled
  }

  get localParticipant() {
    return this.room.localParticipant
  }

  get localVideoTrack() {
    return this.localParticipant.videoTracks.values().next().value.track
  }

  get localAudioTrack() {
    return this.localParticipant.audioTracks.values().next().value.track
  }
}
