import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    id: String,
    title: String,
    local: String,
    frame: Object,
    form: String,
    closeOnFormSuccess: Boolean,
    destroyOnClose: Boolean
  }

  connect() {
    this.element.addEventListener('click', this.handleClick.bind(this))
  }

  disconnect() {
    this.element.removeEventListener('click', this.handleClick)
  }

  get modal() {
    return document.getElementById(this.idValue)
  }

  handleClose() {
    if (this.localParent) {
      this.localParent.append(this.localElement)
      this.localParent = null
    }
  }

  handleClick(event) {
    event.stopPropagation()
    event.preventDefault()
    this.createDialog()
    this.modal.showModal()
  }

  copyLocalElement() {
    if (!this.localValue) return

    this.localElement = document.querySelector(this.localValue)
    this.localParent = this.localElement.parentNode
    const dialogContent = this.modal.querySelector('.content')
    dialogContent.innerHTML = ''
    dialogContent.append(this.localElement)
  }

  createDialog() {
    if (!this.modal) {
      const template = document.querySelector('#turbo_modal_template')
      const clone = template.content.cloneNode(true)
      const dialog = clone.querySelector('dialog')
      const dialogTitle = dialog.querySelector('.title')
      const dialogContent = dialog.querySelector('.content')
      const frame = dialogContent.querySelector('turbo-frame')

      if (this.hasFormValue) dialog.dataset.dialogFormValue = this.formValue
      if (this.hasCloseOnFormSuccessValue) dialog.dataset.dialogCloseOnFormSuccessValue = this.closeOnFormSuccessValue
      if (this.hasDestroyOnCloseValue) dialog.dataset.dialogDestroyOnCloseValue = this.destroyOnCloseValue

      dialog.id = this.idValue
      dialogTitle.innerHTML = this.hasTitleValue ? this.titleValue : this.element.textContent

      if (this.hasFrameValue) {
        for (const [key, value] of Object.entries(this.frameValue)) {
          frame.setAttribute(key, value)
        }
      }

      dialog.addEventListener('close', this.handleClose.bind(this))
      document.body.appendChild(clone)
    }

    this.copyLocalElement()
  }
}
