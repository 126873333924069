import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    form: { type: String, default: 'form' },
    closeOnFormSuccess: { type: Boolean, default: true },
    destroyOnClose: { type: Boolean, default: false }
  }

  connect() {
    this.element.addEventListener('turbo:frame-load', this.handleFrameLoad.bind(this))
  }

  disconnect() {
    this.element.removeEventListener('turbo:frame-load', this.handleFrameLoad)
  }

  handleFrameLoad() {
    if(this.form) {
      this.form.addEventListener('turbo:submit-end', (turboEvent) => {
        if (turboEvent.detail.formSubmission.result.success === true) {
          if (!turboEvent.detail.formSubmission.result.fetchResponse.response.headers.has("KEEP_MODAL")) {
            if (this.closeOnFormSuccessValue) {
              this.close()
            }
          }
        }
      })
    }
  }

  get form() { return this.element.querySelector(this.formValue) }

  close() {
    this.element.close()
    if (this.destroyOnCloseValue) { this.element.remove() }
  }
}
